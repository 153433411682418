<template>
  <v-app-bar app elevation="4" clipped-left dense color="primary" dark>
    <div>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-app-bar-nav-icon
            v-on="on"
            v-bind="attrs"
            @click="setSidebarDrawer(!Sidebar_drawer)"
          />
        </template>
        <span>Invertir menú</span>
      </v-tooltip>
    </div>

    <v-toolbar-title class="pt-1 align-center d-flex">
      <span class="logo-text ml-2 mr-1">
        <span class="cursor-default">Firmar</span
        ><span class="cursor-default font-weight-bold">Online</span>
      </span>
    </v-toolbar-title>

    <v-spacer />
    <AppBarUserMenu></AppBarUserMenu>
    <!---User -->
  </v-app-bar>
</template>
<script>
// Utilities
import { mapState, mapMutations } from "vuex";
import AppBarUserMenu from "./AppBarUserMenu";

export default {
  name: "Header",

  components: {
    AppBarUserMenu
  },

  props: {
    value: {
      type: Boolean,
      default: false
    }
  },

  mounted() {
    if (!this.$notificationsHub.isConnected()) {
      this.$notificationsHub.connect();
    }
    this.getUserCredits();
    this.getUserProfile();
    this.$notificationsHub.$on("credits-consumed", this.getUserCredits);
  },

  beforeDestroy() {
    this.$notificationsHub.$off("credits-consumed");
  },

  data: () => ({
    isUserCreditsLoading: false,
    isUserProfileLoading: false,
    userprofile: [
      { title: "Ajustes", to: "/settings", click: undefined },
      { title: "Mi Perfil", to: "/profile", click: undefined },
      { title: "Cerrar Sesión", to: "/" }
    ]
  }),

  computed: {
    ...mapState(["Sidebar_drawer"]),
    logoSubtitle() {
      return this.$store.state.membershipPlanName;
    }
  },

  methods: {
    ...mapMutations({
      setSidebarDrawer: "SET_SIDEBAR_DRAWER"
    }),
    onUpgradePlan() {
      this.$store.dispatch("setSeeUpgradePurchase");
    },
    onClickSubtitle() {
      this.onUpgradePlan();
    },
    getUserCredits() {
      this.isUserCreditsLoading = true;
      this.$store
        .dispatch("user/getUserCredits")
        .then(() => {
          this.isUserCreditsLoading = false;
        })
        .catch(err => {
          this.isUserCreditsLoading = false;
          console.log(err);
        });
    },
    getUserProfile() {
      this.isUserProfileLoading = true;
      this.$store
        .dispatch("user/getUserProfile")
        .then(() => {
          this.isUserProfileLoading = false;
        })
        .catch(err => {
          this.isUserProfileLoading = false;
          console.log(err);
        });
    }
  }
};
</script>
