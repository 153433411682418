import {
  mdiSendOutline,
  mdiFileDownloadOutline,
  mdiInboxOutline,
  mdiFileExcelOutline,
  mdiClipboardFlowOutline
} from "@mdi/js";

export default [
  {
    title: "Enviados",
    icon: mdiSendOutline,
    to: "/signatures/remote/sent"
  },
  {
    title: "Recibidos",
    icon: mdiInboxOutline,
    to: "/signatures/remote/received"
  },
  {
    title: "Flujos",
    icon: mdiClipboardFlowOutline,
    to: "/signatures/remote/flows"
  },
  {
    title: "Comb. Correspondencia",
    icon: mdiFileExcelOutline,
    to: "/signatures/remote/correspondence-combination"
  },
  {
    title: "Descarga masiva",
    icon: mdiFileDownloadOutline,
    to: "/signatures/remote/bulk-download"
  }
];
