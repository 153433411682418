<template>
  <v-footer padless class="white elevation-2">
    <v-col class="text-end" cols="12">
      <span class="grey--text text--darken-2 mr-3 font-size-small">
        © {{ new Date().getFullYear() }} edatalia data solutions - firmar.online
      </span>
    </v-col>
  </v-footer>
</template>
<script>
export default {
  name: "Footer"
};
</script>
