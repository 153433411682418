<template>
  <v-menu
    offset-y
    left
    nudge-bottom="14"
    min-width="230"
    active-class="highlighted"
    content-class="user-profile-menu-content"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn icon x-large v-bind="attrs" v-on="on">
        <v-icon>mdi-account-circle</v-icon>
      </v-btn>
    </template>

    <v-list>
      <div class="pb-3 pl-4 pt-2">
        <v-avatar size="40px">
          <v-icon size="46px">mdi-account-circle</v-icon>
        </v-avatar>
        <div
          class="d-inline-flex flex-column justify-center ms-3"
          style="vertical-align: middle"
        >
          <span
            class="ellipsis-dots user-name-app-bar text--primary font-weight-semibold mb-n1"
          >
            {{ username }}</span
          >
          <small class="text--disabled mt-1">{{ credits + " créditos" }}</small>
        </div>
      </div>

      <v-divider></v-divider>

      <!-- Profile -->
      <v-list-item link class="pl-6" to="/profile">
        <v-list-item-icon class="mr-2">
          <v-icon size="22">
            {{ icons.mdiAccountOutline }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Mi Perfil</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item v-if="isUserAdmin" link class="pl-6" to="/my-company">
        <v-list-item-icon class="mr-2">
          <v-icon size="22">
            {{ icons.mdiDomain }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Mi Empresa</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <!-- Settings -->
      <v-list-item link class="pl-6" to="/settings">
        <v-list-item-icon class="mr-2">
          <v-icon size="22">
            {{ icons.mdiCogOutline }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Integración API</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider class="my-2"></v-divider>

      <v-list-item link class="pl-6" to="/signatures/face-to-face">
        <v-list-item-icon class="me-2">
          <v-icon size="22">
            {{ icons.mdiSignatureFreehand }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Firma Presencial</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider class="my-2"></v-divider>

      <!-- FAQ -->
      <v-list-item link class="pl-6" @click="onClickGoCentroAyuda">
        <v-list-item-icon class="me-2">
          <v-icon size="22">
            {{ icons.mdiHelpCircleOutline }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Soporte</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <!-- Contact -->
      <v-list-item link class="pl-6" @click="onClickGoContactaAsesor">
        <v-list-item-icon class="me-2">
          <v-icon size="22">
            {{ icons.mdiPhoneInTalkOutline }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Contáctanos</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-divider class="my-2"></v-divider>

      <!-- Logout -->
      <v-list-item link class="pl-6" @click.prevent="logout">
        <v-list-item-icon class="me-2">
          <v-icon size="22">
            {{ icons.mdiLogoutVariant }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Cerrar Sesión</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-menu>
</template>
<script>
import {
  mdiAccountOutline,
  mdiEmailOutline,
  mdiCheckboxMarkedOutline,
  mdiChatOutline,
  mdiCogOutline,
  mdiCurrencyUsd,
  mdiHelpCircleOutline,
  mdiSignatureFreehand,
  mdiLogoutVariant,
  mdiPhoneInTalkOutline,
  mdiDomain
} from "@mdi/js";
import { GoToUrl } from "../../../utils/utils";

export default {
  name: "AppBarUserMenu",
  data: () => ({
    icons: {
      mdiAccountOutline,
      mdiEmailOutline,
      mdiCheckboxMarkedOutline,
      mdiChatOutline,
      mdiCogOutline,
      mdiCurrencyUsd,
      mdiHelpCircleOutline,
      mdiSignatureFreehand,
      mdiLogoutVariant,
      mdiPhoneInTalkOutline,
      mdiDomain
    }
  }),
  computed: {
    username() {
      return this.$store.state.user.userProfile != undefined
        ? this.$store.state.user.userProfile.fullName
        : "";
    },

    isUserAdmin() {
      return this.$store.getters["user/isLoggedUserAdmin"];
    },

    credits() {
      return this.$store.getters["user/credits"];
    }
  },
  methods: {
    onClickGoCentroAyuda() {
      GoToUrl("https://edatalia.com/kb/firmar-online/");
    },
    onClickGoContactaAsesor() {
      GoToUrl("https://firmar.online/contactanos/");
    },
    logout() {
      this.$store.dispatch("logout");
      this.$auth.signOut("/");
    }
  }
};
</script>

<style lang="scss"></style>
