<template>
  <v-navigation-drawer
    app
    v-model="Sidebar_drawer"
    mobile-breakpoint="960"
    clipped
    id="main-sidebar"
  >
    <v-list nav>
      <v-list-item-group color="primary">
        <v-subheader class="pl-1">Firma Remota</v-subheader>

        <v-list-item
          v-for="(item, i) in items"
          :key="i"
          :value="item"
          :to="item.to"
          active-color="primary"
        >
          <v-list-item-icon class="mr-3">
            <v-icon v-text="item.icon"></v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title v-text="item.title"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>

    <v-divider></v-divider>

    <v-list nav>
      <v-subheader class="pl-1">Herramientas</v-subheader>
      <v-list-item-group color="primary">
        <v-list-item
          v-for="(item, i) in toolItems"
          :key="i"
          :value="item"
          :to="item.to"
          active-color="primary"
        >
          <v-list-item-icon class="mr-3">
            <v-icon v-text="item.icon"></v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title v-text="item.title"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { mdiMenuDown, mdiPlus } from "@mdi/js";
import SidebarItems from "./SidebarItems";
import SidebarToolItems from "./SidebarToolItems";

export default {
  name: "Sidebar",

  data: () => ({
    mdiPlus: mdiPlus,
    mdiMenuDown: mdiMenuDown,
    items: SidebarItems,
    toolItems: SidebarToolItems
  }),

  methods: {
    onUpgradePlan() {
      this.$store.dispatch("setSeeUpgradePurchase");
    }
  },

  computed: {
    Sidebar_drawer: {
      get() {
        return this.$store.state.Sidebar_drawer;
      },
      set(val) {
        this.$store.commit("SET_SIDEBAR_DRAWER", val);
      }
    }
  }
};
</script>
