import {
  mdiFileCertificateOutline,
  mdiCheckDecagramOutline,
  mdiBookClockOutline
} from "@mdi/js";

export default [
  {
    title: "Añadir Firma Digital",
    icon: mdiFileCertificateOutline,
    to: "/tools/digital-signature"
  },
  {
    title: "Añadir Sello De Tiempo",
    icon: mdiBookClockOutline,
    to: "/tools/timestamp-signature"
  },
  {
    title: "Validar Documento",
    icon: mdiCheckDecagramOutline,
    to: "/tools/document-validation"
  }
];
